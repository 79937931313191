export const CARD_STATUS_VALID = 'valid';
export const CARD_STATUS_REQUIRED = 'required';

export const ACCOUNT_DETAILS = window.Chargebee.getPortalSections().ACCOUNT_DETAILS;
export const ADDRESS = window.Chargebee.getPortalSections().ADDRESS;
export const PAYMENT_SOURCES = window.Chargebee.getPortalSections().PAYMENT_SOURCES;
export const BILLING_HISTORY = window.Chargebee.getPortalSections().BILLING_HISTORY;

export function showChargebeePortal(sectionType = PAYMENT_SOURCES, onClose) {
  const portal = window.charge.createChargebeePortal();
  portal.openSection({ sectionType }, { close: () => onClose && onClose() });
}