export const USER_STATUS_TRIAL = 'trial';
export const USER_STATUS_REGISTERED = 'registered';
export const USER_STATUS_ACTIVE = 'active';

export const PRODUCT_G_CONNECTOR = 'g-connector';
export const PRODUCT_XL_CONNECTOR = 'xl-connector';
export const PRODUCT_DATA_CONNECTOR = 'data-connector';

export const PRODUCTS = [
  {
    product_id: PRODUCT_G_CONNECTOR,
    name: 'G-Connector',
  },
  {
    product_id: PRODUCT_XL_CONNECTOR,
    name: 'XL-Connector',
  },
  {
    product_id: PRODUCT_DATA_CONNECTOR,
    name: 'Google Data Connector',
  },
];

export const SUBSCRIPTION_STATUS_ACTIVE = 'active';
export const SUBSCRIPTION_STATUS_IN_TRIAL = 'in_trial';
export const SUBSCRIPTION_STATUS_CANCELLED = 'cancelled';
export const SUBSCRIPTION_STATUS_PAUSED = 'paused';
export const SUBSCRIPTION_STATUS_NON_RENEWING = 'non_renewing';

export const PLAN_STATUS = 'active';

export const SUBSCRIPTION_OPTION_REACTIVATE = 'reactivate';
export const SUBSCRIPTION_OPTION_PROLONG = 'prolong';
export const SUBSCRIPTION_OPTION_PURCHASE = 'purchase';

export const CARD_STATUS_VALID = 'valid';
export const CARD_STATUS_EXPIRING = 'expiring';
export const CARD_STATUS_EXPIRED = 'expired';
export const CARD_STATUS_PENDING = 'pending_verification';

export const PAYMENT_TYPE_CARD = 'card';
export const PAYMENT_TYPE_CHECKOUT = 'paypal_express_checkout';
export const PAYMENT_TYPE_DIRECTDEBIT = 'direct_debit';

export const PAYMENT_STATUS_REQUIRED = 'required';
export const PAYMENT_STATUS_OPTINAL = 'optional';