import { AppBar } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BottomBar = styled(AppBar)({
  top: 'auto',
  height: 56,
  bottom: 0,
  display: 'flex',
  flexDirection: "column",
  alignItems: 'center',
  justifyContent: 'center',
})