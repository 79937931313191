import { createContext, useContext } from 'react';
import { autorun, configure } from 'mobx';

import UserStore from './userStore';

const STORAGE_PREFIX = '__portal_';

const userStore = new UserStore();
try { userStore.token = JSON.parse(localStorage.getItem(`${STORAGE_PREFIX}token`)) } catch { }

configure({ enforceActions: 'never' });

autorun(() => {
  localStorage.setItem(`${STORAGE_PREFIX}token`, JSON.stringify(userStore.token ?? ''));
})


const StoreContext = createContext();
export const StoreProvider = ({ children }) => <StoreContext.Provider value={{ userStore }} children={children} />

export function useStore() {
  return useContext(StoreContext);
}

export { userStore }