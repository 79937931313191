import { useState, useMemo, useEffect, useRef } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import accounting from 'accounting';
import { Unstable_Grid2 as Grid, Box, Button, InputLabel, TextField, Select, MenuItem } from '@mui/material';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Card, CardActions, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { runAction } from 'src/services/utils';
import { ReactLink } from 'src/components/base/ReactLink';
import { MultilineTypography } from 'src/components/base/MultilineTypography';
import { reflect } from 'shared/utils/utils';
import { useStore } from 'src/stores';
import { SUBSCRIPTION_STATUS_ACTIVE } from 'shared/services/chargebee/constants';

import logoImage from 'assets/images/logo-header.svg';

const terms = [
  { key: 'month_1', title: 'Monthly' },
  { key: 'year_1', title: 'Annual (17% off)' },
];

export const Checkout = observer(() => {
  const checkoutRef = useRef();
  const { userStore } = useStore();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { subsciption_id } = useParams();

  const origin_plan_id = searchParams.get('p');
  const coupon = searchParams.get('coupon');

  const [plan, setPlan] = useState({});
  const [term, setTerm] = useState(terms[0].key);
  const [quantity, setQuantity] = useState(1);
  const [estimate, setEstimate] = useState();

  const isLoading = userStore.isLoading;

  const plans = useMemo(() => {
    const { plans = [] } = userStore;
    const products = new Set((!!subsciption_id && !!origin_plan_id && plans.find(({ id }) => id === origin_plan_id)?.products || []).map(({ id }) => id));

    return plans.filter(plan => plan.status === SUBSCRIPTION_STATUS_ACTIVE && term === `${plan.period_unit}_${plan.period}`)
      .filter(plan => !products.size || (plan.products || []).some(({ id }) => products.has(id)));
  }, [userStore.plans, plan.id, term, subsciption_id, origin_plan_id]);

  useEffect(() => {
    runAction(async () => {
      await reflect(userStore.logout());
      await userStore.fetchPlans();

      if (origin_plan_id) {
        const plan = userStore.plans.find(({ id }) => id === origin_plan_id);
        if (plan) {
          setPlan(plan);
          setTerm(`${plan.period_unit}_${plan.period}`);
        }
      }
    });
  }, []);

  useEffect(() => {
    runAction(async () => {
      if (!!plan.id) {
        const estimate = await userStore.estimateCheckout({
          subsciption_id, plan_id: plan.id, plan_quantity: quantity, coupon
        });
        setEstimate(estimate);
      }
    });
  }, [plan, quantity, subsciption_id]);

  function handleChangePlan(event) {
    setPlan(plans.find(({ id }) => event.target.value === id));
  }

  function handleChangeTerm(event) {
    const newTerm = event.target.value;
    const newPlan = userStore.plans.find(({ id, status, common_id, period_unit, period }) =>
      plan.id && id !== plan.id
      && status === SUBSCRIPTION_STATUS_ACTIVE
      && newTerm === `${period_unit}_${period}`
      && common_id === plan.common_id
    );

    setPlan(newPlan || {});
    setTerm(newTerm);
  }

  function handleCheckout() {
    window.charge.openCheckout({
      hostedPage: async () => await userStore.proceedCheckout({
        subsciption_id, plan_id: plan.id, plan_quantity: quantity, coupon
      }),
      success: async (id) => {
        checkoutRef.current = runAction(async () => await userStore.getCheckout({ id }));
      },
      error: ({ message }) => userStore.displayErrorSnack(message),
      close: finilizeCheckout,
    });
  }

  async function finilizeCheckout() {
    if (!checkoutRef.current) { return }

    const { status } = await checkoutRef.current;
    const message = status === 'new'
      ? 'Thank you for your purchase! We’ve created a customer portal account for you and sent you a confirmation email. Please confirm your account to be able to log in to the customer portal.'
      : 'Thank you for your purchase! It looks like you already have an account with us. Please log in to the portal with your email address and password below.';

    userStore.displaySuccessSnack(message);
    navigate('/');
  }

  return (
    <Grid xs={11} md={7} >
      <Card>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
            <HeaderLogo />
          </Box>
          {!subsciption_id &&
            <p>After you complete the checkout, a new account will be created in our system for you. If you already have an account with us, please <ReactLink to="/">log in here</ReactLink> to purchase a new or manage an existing subscription.</p>
          }
        </CardContent>

        <CardContent>
          <FormControl fullWidth variant="standard">
            <InputLabel htmlFor="plan">Select Plan</InputLabel>
            <Select value={plan.id ?? ''} onChange={handleChangePlan} inputProps={{ id: 'plan' }} disabled={isLoading}>
              {
                plans.map(i => <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>)
              }
            </Select>
          </FormControl>

          <p /><MultilineTypography variant="caption" description={plan.description ?? ''} />
        </CardContent>

        <CardContent>
          <FormControl fullWidth component="fieldset" >
            <FormLabel component="legend">Billing Period</FormLabel>
            <RadioGroup aria-label="term" value={term} onChange={handleChangeTerm}>
              {
                terms.map(term =>
                  <FormControlLabel key={term.key} value={term.key} control={<Radio />} label={term.title} disabled={isLoading} />)
              }
            </RadioGroup>
          </FormControl>
        </CardContent>

        <CardContent>
          <TextField fullWidth label="Number of Licenses" type="number" variant="standard"
            value={quantity} onChange={event => setQuantity(+event.target.value)} disabled={isLoading} />
        </CardContent>

        {!!estimate &&
          <CardContent>
            <Estimate {...estimate} />
          </CardContent>
        }

        <CardActions>
          <Button onClick={handleCheckout} color="secondary" variant="contained" disabled={!plan.id || userStore.isLoading}>Checkout</Button>
        </CardActions>

      </Card>
    </Grid >
  )
})

const HeaderLogo = styled('div')({
  width: '200px',
  height: '48px',
  background: `url(${logoImage}) no-repeat`,
  backgroundSize: 'cover',
})

const Estimate = ({ currency_code, total, discount_description }) => {
  const value = accounting.formatMoney(total / 100, { symbol: currency_code, format: '%s %v' })

  return (
    <Grid container alignItems="center" justifyContent="flex-start" direction="row"
      sx={{ minHeight: 48, bgcolor: '#C7F2EB', px: 2, py: 1 }}>
      <Typography variant="button">Total:&nbsp;</Typography>
      <Typography variant="button" sx={{ fontWeight: 800 }}>{value}</Typography>
      {!!discount_description && <Typography>&nbsp;– {discount_description} coupon applied</Typography>}

    </Grid>
  )
}