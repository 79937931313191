export function pick(obj = {}, keys) {
  return Object.entries(obj)
    .filter(([key]) => keys.includes(key))
    .reduce((obj, [key, val]) => Object.assign(obj, { [key]: val }), {});
}

export function omit(obj = {}, keys) {
  return Object.entries(obj)
    .filter(([key]) => !keys.includes(key))
    .reduce((obj, [key, val]) => Object.assign(obj, { [key]: val }), {});
}

export async function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export async function timeoutPromise(ms, promise) {
  let intervalId, resolveTimeout;
  const timeout = new Promise((resolve, reject) => {
    resolveTimeout = resolve;
    intervalId = setTimeout(() => {
      clearTimeout(intervalId), intervalId = null;
      reject(new Error('Total report execution time limit exceeded'));
    }, ms);
  });

  const result = await Promise.race([promise, timeout]);
  intervalId && clearInterval(intervalId);
  resolveTimeout && resolveTimeout();
  return result;
}

export function reflect(promise) {
  return promise.then(data => ({ data, status: 'fulfilled' }), (error) => {
    console.warn('Reflect:', error.message);
    return { error, status: 'rejected' };
  });
}

export function compareInsensitivelyStrings(str1, str2) {
  return typeof str1 === 'string' && typeof str2 === 'string'
    ? str1.localeCompare(str2, undefined, { sensitivity: 'accent' }) === 0
    : str1 === str2;
}

export function capitalizeString(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function toQueryString(data) {
  return Object.entries(data)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
}

export function simpleDeepCopy(array = []) {
  return array.map(x => Object.entries(x)
    .reduce((obj, [key, val]) => Object.assign(obj, { [key]: Array.isArray(val) ? simpleDeepCopy(val) : val }), {}))
}

export function lowercaseKeys(object) {
  const result = {};
	if (object && typeof object === 'object' && !Array.isArray(object)) {
		Object.keys(object).forEach(key => result[key.toLowerCase()] = object[key]);
	}
	return result;
}

export function findByInsensitiveKey(object, key) {
  if (!object || typeof object !== 'object') { return null }

  key = key.toLowerCase();
  const result = Object.keys(object).find(key1 => key1.toLowerCase() === key);

  return object[result];
}

export function cancelablePromise(task, cancelCallback) {
  let isFinished = false;
  let cancelFunction = () => {
    cancelCallback && cancelCallback();
    isFinished = true;
  }

  const promise = new Promise((resolve, reject) => {
    if (!isFinished) { // may happen if we call cancelFunction() immideately after creation
      return task(resolve, reject, cancelFunction);
    }
  })
  .then((value) => {
    if (!isFinished) {
      isFinished = true;
      return null;
    }
    return value;
  })
  .catch((err) => {
    console.log('Cancelable promise catch: ', err.message);
    isFinished = true;
    return err;
  });

  return { promise, cancelFunction };
}