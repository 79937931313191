import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Unstable_Grid2 as Grid, Paper, Typography, Link } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import { ReactIconButton } from 'src/components/base/ReactIconButton';
import { Copyright } from 'src/components/base/Copyright';

import { runAction } from 'src/services/utils';
import { useStore } from 'src/stores';

import footerLogoImage from 'assets/images/logo-footer.svg';

const FooterLogoLink = styled(Link)({
  display: 'block',
  width: 88,
  height: 21,
  background: `url(${footerLogoImage}) no-repeat`,
})

export const PrimaryContainer = () => {
  const location = useLocation();
  const { userStore } = useStore();

  useEffect(() => { runAction(async () => await userStore.logout()) }, []);

  const isRoot = location.pathname === '/' || location.pathname.startsWith('/checkout');
  return (
    <Grid sx={{ backgroundColor: '#FAFAFA', width: 1, minHeight: 1 }} container direction="column" alignItems="center" justifyContent="center">

      {!isRoot &&
        <Paper sx={{ width: 1, height: 48 }}>
          <Grid container alignItems="center">
            <ReactIconButton to="/"><ArrowBackIcon /></ReactIconButton>
            <Typography variant="h6" noWrap>Home</Typography>
          </Grid>
        </Paper>
      }

      <Grid xs sx={{ width: 1, mx: 0, my: 1 }} container alignContent="center" justifyContent="center" alignItems="center" direction="column">
        <Outlet />
      </Grid>

      <Grid container sx={{ width: 1, minHeight: 64, backgroundColor: 'secondary.main', px: 3, py: 1 }} alignItems="center" justifyContent="space-between" wrap="wrap">
        <Grid xs='auto'>
          <FooterLogoLink href="https://www.xappex.com/" rel="noopener" />
        </Grid>
        <Grid container md columnSpacing={1} disableEqualOverflow justifyContent="end">
          <Grid>
            <Typography sx={{ color: 'secondary.contrastText' }} noWrap><Copyright /> All rights reserved.</Typography>
          </Grid>
          <Grid>
            <Link sx={{ color: 'secondary.contrastText' }} href="https://www.xappex.com/docs/xappex-privacy-policy/" underline="none" target="_blank" rel="noopener">Privacy</Link>
          </Grid>
          <Grid>
            <Link sx={{ color: 'secondary.contrastText' }} href="https://www.xappex.com/?s=Terms+and+Conditions" underline="none" target="_blank" rel="noopener">Terms of Use</Link>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  )
}