import { forwardRef } from 'react';

export const SubmitAction = forwardRef(({ action, token, type = 'Bearer', target, params = [] }, ref) => {
  return (
    <form action={action} ref={ref} target={target} method="post">
      <input type="hidden" name="type" value={type} />
      <input type="hidden" name="token" value={token} />
      {Object.entries(params).map(([name, value]) => <input type="hidden" key={name} name={name} value={value} />)}
    </form>
  )
})