import { useState, useMemo, useCallback, forwardRef, useRef } from 'react';
import { Dialog } from '@mui/material';

export function useModalDialog() {
  const promise = useRef();
  const [open, setOpen] = useState(false);
  const [props, setProps] = useState({});

  function handleOnClose(data) {
    setOpen(false);

    promise.current && promise.current(data);
    promise.current = null;
  }

  return useMemo(() => ({
    register: () => ({ open, onClose: handleOnClose, ...props }),
    open: async (props) => {
      if (promise.current) { throw new Error('useModalDialog: unresolved promise') }

      return new Promise((resolve) => {
        promise.current = resolve;
        setProps(props);
        setOpen(true);
      });
    },
  }));
}

export function withModalDialog(WrappedComponent, { backdrop = true, ...dialogPros } = {}) {
  return forwardRef(({ open, ...props }, ref) => {
    const handleClose = useCallback((_, reason) => {
      if (!(reason === 'backdropClick' && !backdrop)) { return props.onClose() }
    }, []);
    return (
      <Dialog open={open} onClose={handleClose} {...dialogPros}>
        <WrappedComponent ref={ref} {...props} />
      </Dialog>
    )
  })
}
