import { useState, useMemo, useRef } from 'react';
import { parseISO } from 'date-fns';
import { useDebouncedCallback } from 'use-debounce';

import { getExportSubscriptionUrl } from 'src/api';
import { Card, CardHeader, CardContent, Typography, IconButton, Chip, Tooltip } from '@mui/material';
import { List, ListItem, ListItemSecondaryAction, ListItemAvatar, ListItemText, Avatar, Toolbar } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, SaveAlt as SaveAltIcon } from '@mui/icons-material';

import { SubmitAction } from 'shared/components/SubmitAction';
import { SearchField } from 'src/components/base/SearchField';
import { AlertDialog } from 'src/components/base/AlertDialog';
import { LicenseDialog } from './LicenseDialog';
import { useModalDialog } from 'src/components/base/ModalDialog';

export const LicenseCard = ({ subscription, licenses, onUpdate, updated_at, manageOnly = false, disabled, token }) => {
  const submitFormRef = useRef();

  const [pending, setPending] = useState(false);
  const debouncedPending = useDebouncedCallback(value => setPending(value), 1000);

  const [filter, setFilter] = useState('');

  const filteredLicenses = useMemo(() => {
    let output = licenses.map((item, index) => ({ ...item, index }));
    if (filter) {
      const val0 = filter.toLowerCase();
      output = output.filter(({ name, license_email }) => (name || '').toLowerCase().includes(val0) || (license_email || '').toLowerCase().includes(val0));
    }
    return output.sort((a1, a2) => {
      const v1 = a1.name || a1.license_email || '';
      const v2 = a2.name || a2.license_email || '';

      if ((v1 && !v2) || (!v1 && v2)) {
        return v1 ? -1 : 1;
      }
      return v1.localeCompare(v2, undefined, { sensitivity: 'accent' });
    });
  }, [licenses, filter]);

  const licenseDialog = useModalDialog();
  const deleteDialog = useModalDialog();

  async function handleSelectLicense(pos = -1) {
    const license = licenses[pos];
    const emails = new Set(licenses.map(({ license_email }) => license_email).filter(item => !!item && item != license?.license_email));

    const result = await licenseDialog.open({ license, validate: email => !emails.has(email) });
    if (!result) { return }

    onUpdate(updateLicense(pos, result));
  }

  async function handleDeleteLicense(pos) {
    if (await deleteDialog.open()) {
      const copyLicenses = [...licenses];
      copyLicenses.splice(pos, 1);
      onUpdate(copyLicenses);
    }
  }

  function handleExportLicenses() {
    if (submitFormRef.current) {
      setPending(true);
      submitFormRef.current.submit();
      debouncedPending(false);
    }
  }

  function updateLicense(pos, license) {
    if (pos < 0 || pos >= licenses.length) { return [...licenses, license] }
    return licenses.map((current, i) => i === pos ? license : current);
  }

  return (
    <Card sx={{ height: 1 }}>
      <CardHeader title="Licenses" action={
        <Toolbar variant="dense" disableGutters>
          <SearchField value={filter} onChange={setFilter} />
          {subscription &&
            <Tooltip enterDelay={500} placement="top" title="Export licenses">
              <IconButton onClick={handleExportLicenses} disabled={pending} size="large"><SaveAltIcon /></IconButton>
            </Tooltip>
          }
          {!manageOnly &&
            <Tooltip enterDelay={500} placement="top" title="Add license">
              <IconButton onClick={() => handleSelectLicense()} size="large"> <AddIcon /> </IconButton>
            </Tooltip>
          }
        </Toolbar>
      } />

      {licenses.length ?
        <List>
          {filteredLicenses.map((license, i) => (
            <LicenseItem key={license.index} icon={i + 1} updated_at={updated_at} license={license} manageOnly={manageOnly} disabled={disabled}
              onSelectLicense={handleSelectLicense} onDeleteLicense={handleDeleteLicense}
            />
          ))}
        </List>
        : !manageOnly && <CardContent>
          <Typography sx={{ color: 'rgba(0, 0, 0, 0.54)' }} variant="caption">Press + to add licenses</Typography>
        </CardContent>
      }

      <LicenseDialog {...licenseDialog.register()} />

      <AlertDialog {...deleteDialog.register()} title="Delete License" cancelable>
        This action will remove a user slot that has already been paid for from your subscription and credit your account with a prorated amount for such deletion. Are you sure you want to remove this user slot from your subscription?
      </AlertDialog>

      <SubmitAction ref={submitFormRef} action={getExportSubscriptionUrl()}
        token={token} type="Basic" params={{ id: subscription }} 
      />
    </Card>
  );
}

const LicenseItem = ({ license, icon, onSelectLicense, onDeleteLicense, manageOnly, disabled }) => {
  const { name, license_email, license_serial, serial_description } = license;
  const isEmpty = !(name || license_serial || license_email);

  const title = isEmpty ? '...' : (name || '');
  const subtitle = [license_email && `Email: ${license_email}`, license_serial && `Hardware: ${serial_description || license_serial}`].filter(Boolean);
  const dateAt = license.checked_at && `Last used: ${parseISO(license.checked_at).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })}`;

  return (
    <ListItem disabled={disabled} button onClick={() => onSelectLicense(license.index)}>
      <ListItemAvatar><Avatar>{icon}</Avatar></ListItemAvatar>

      <ListItemText primary={title}
        secondaryTypographyProps={{ children: 'textSecondary' }}
        secondary={<span dangerouslySetInnerHTML={{ __html: subtitle.join('<br />') }} />}
      />

      <ListItemSecondaryAction>
        {dateAt && <Chip label={dateAt} size="small" />}

        {!manageOnly &&
          <Tooltip enterDelay={500} placement="top" title={'Delete License'}>
            <IconButton
              disabled={disabled}
              onClick={() => onDeleteLicense(license.index)}
              aria-label="Delete"
              size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        }
      </ListItemSecondaryAction>
    </ListItem>
  );
}