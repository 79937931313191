import { createContext, useContext, useEffect } from 'react';

const GoogleAnalyticsContext = createContext(undefined);

export const GoogleAnalyticsProvider = ({ measurementId, traffic_type, children }) => {
  function getGTag() {
    if (!window.gtag) {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function _gtag() {
        window.dataLayer.push(arguments);
      };

      const params = { send_page_view: false };
      if (traffic_type) { Object.assign(params, { traffic_type }) }

      window.gtag('js', new Date());
      window.gtag('config', measurementId, params);
    }

    return window.gtag;
  }

  useEffect(() => {
    if (measurementId) {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
      document.head.prepend(script);
    }
  }, []);

  return <GoogleAnalyticsContext.Provider value={getGTag()}>
    {children}
  </GoogleAnalyticsContext.Provider>
}

export const useGoogleAnalytics = () => {
  const context = useContext(GoogleAnalyticsContext);
  if (context === undefined) { throw new Error('useGoogleAnalytics must be used within a GoogleAnalyticsProvider') }
  return context;
}
