
export function sanitizeTemplateParameters(template = [], params = []) {
  const optionsTemplate = new Set();
  template.forEach(product => product.options?.forEach(option => optionsTemplate.add(`${product.id}/${option.id}`)));

  let isModified = false;
  const products = params.reduce((products, product) => {
    const options = product.options.reduce((values, option) => {
      if (optionsTemplate.has(`${product.id}/${option.id}`)) {
        values.push(option);
      }
      else {
        isModified = true;
      }
      return values;
    }, []);

    if (options.length) {
      products.push({ id: product.id, options });
    }
    return products;
  }, []);

  return { products, isModified };
}
