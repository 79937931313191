import accounting from 'accounting';
import { observer } from 'mobx-react';
import { CardActions, Button, Unstable_Grid2 as Grid, Typography } from '@mui/material';

import { SUBSCRIPTION_STATUS_ACTIVE, SUBSCRIPTION_STATUS_IN_TRIAL, SUBSCRIPTION_STATUS_CANCELLED } from 'shared/services/chargebee/constants';
import { SUBSCRIPTION_OPTION_PURCHASE } from 'shared/services/chargebee/constants';

import { runAction } from 'src/services/utils';
import { useStore } from 'src/stores';

export const SubscriptionFooter = observer(({ subscription, onChange }) => {
  const { userStore } = useStore();
  const { customer } = userStore;

  function handleReactivateSubscription() {
    runAction(async () => {
      const result = await userStore.estimateSubscription(subscription, { status: SUBSCRIPTION_STATUS_ACTIVE });
      onChange(result);
    });
  }

  if (!!subscription.estimates) {
    return <EstimatesBar data={subscription.estimates} />
  }

  const { status, cancelled_option } = subscription.data;
  if (status !== SUBSCRIPTION_STATUS_IN_TRIAL && status !== SUBSCRIPTION_STATUS_CANCELLED ||
    (!customer.auto_collection && status === SUBSCRIPTION_STATUS_CANCELLED)) { return null }

  const data = { title: 'Reactivate Subscription', callback: handleReactivateSubscription };
  if (status === SUBSCRIPTION_STATUS_IN_TRIAL) {
    Object.assign(data, { title: 'Convert to paid license' });
  }
  else if (cancelled_option === SUBSCRIPTION_OPTION_PURCHASE) {
    Object.assign(data, { title: 'Purchase Subscription' });
  }

  return (
    <CardActions>
      <Button size="small" color="secondary" onClick={data.callback}>{data.title}</Button>
    </CardActions>
  )
})

const EstimatesBar = ({ data }) => {
  const { subscription_estimate, unbilled_charge_estimates = {}, credit_note_estimates = {} } = data;
  const messages = [];

  if (!unbilled_charge_estimates.amount && !unbilled_charge_estimates.voided_amount && !credit_note_estimates.total) {
    messages.push('Modifications will be applied without charges');
  }

  if (unbilled_charge_estimates.amount || unbilled_charge_estimates.voided_amount) {
    const total = unbilled_charge_estimates.amount - unbilled_charge_estimates.voided_amount;
    const value = accounting.formatMoney(Math.abs(total / 100), { symbol: subscription_estimate.currency_code, format: '%s %v' });
    messages.push(total < 0 ? `<strong>${value}</strong> will be removed from the charges.` : `A charge of <strong>${value}</strong> will be added to the charges.`);

    if (unbilled_charge_estimates.discount_amount) {
      const discount_amount = accounting.formatMoney(unbilled_charge_estimates.discount_amount / 100, { symbol: subscription_estimate.currency_code, format: '%s %v' });
      messages.push(`Discount of <strong>${discount_amount}</strong> will be applied.`);
    }
  }

  if (credit_note_estimates.total) {
    const total = accounting.formatMoney(credit_note_estimates.total / 100, { symbol: subscription_estimate.currency_code, format: '%s %v' });
    messages.push(`Renewal credits worth <strong>${total}</strong> will be created.`);
  }

  return (
    <Grid container alignItems="flex-start" justifyContent="center" direction="column"
      sx={{ minHeight: 48, bgcolor: '#C7F2EB', px: 2, py: 1 }}
    >
      {messages.map((text, i) => <Paragraph key={i} variant="caption" text={text} />)}
    </Grid>
  )
}

const Paragraph = ({ text, ...props }) => (
  <Typography {...props} dangerouslySetInnerHTML={{ __html: text }} />
)
