import { useState, useRef, useEffect } from 'react';

export function useEffectState(initialState, deps) {
  const mountRef = useRef(false);
  const [value, setValue] = useState(initialState);

  useEffect(() => {
    mountRef.current && setValue(typeof initialState === 'function' ? initialState() : initialState);
    mountRef.current = true;
  }, deps);
  return [value, setValue];
}