import { useState, useMemo } from 'react';
import { DialogTitle, DialogActions, DialogContent, Button } from '@mui/material';
import { List, ListItem, ListItemSecondaryAction, ListItemText, Checkbox, Input, Divider } from '@mui/material';
import { withModalDialog } from 'src/components/base/ModalDialog';

export function usePlanTemplate(products = []) {
  return useMemo(() => products.reduce((products, { id, options }) => {
    options?.length && products.push({ id, options });
    return products;
  }, []), [products])
}

export const SubscriptionTemplateDialog = withModalDialog(({ template, products, onClose }) => {
  const items = useMemo(() => {
    const values = new Map();
    products.forEach(product =>
      product.options?.forEach(option =>
        values.set(`${product.id}/${option.id}`, option.value)));

    return template.map(({ id, options }) => (
      { id, options: options.map(option => ({ ...option, value: values.get(`${id}/${option.id}`) ?? option.defaultValue })) }
    ))
  }, [template, products])

  function handleClose() {
    const products = items.map(({ id, options }) => (
      { id, options: options.map(option => ({ id: option.id, value: option.value })) }
    ));
    onClose(products);
  }

  return (
    <>
      <DialogTitle id="form-dialog-title">Configurable subscription features</DialogTitle>

      <DialogContent>
        {items.map(({ id, options }) => (
          <List key={id} dense>
            <Divider />
            {options.map((option) => <OptionItem key={option.id} option={option} />)}
          </List>
        ))}
      </DialogContent>

      <DialogActions>
        <Button autoFocus color="primary" onClick={() => onClose()}>Cancel</Button>
        <Button variant="contained" color="secondary" onClick={handleClose}>Apply</Button>
      </DialogActions>
    </>
  )
}, { fullWidth: true, maxWidth: 'sm' })

const OptionItem = ({ option }) => {
  const [value, setValue] = useState(option.value);
  const Option = useMemo(() => ({ 'boolean': OptionBoolean, 'string': OptionString })[option.type], [option.type]);

  function handleChangeValue(value) {
    option.value = value;
    setValue(value);
  }

  return (
    <ListItem>
      <ListItemText primary={option.name} secondary={option.description} />
      <ListItemSecondaryAction>
        <Option value={value} onChange={handleChangeValue} />
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const OptionBoolean = ({ value, onChange }) => (
  <Checkbox edge="end" checked={value ?? false} onChange={event => onChange(event.target.checked)} />
)

const OptionString = ({ value, onChange }) => (
  <Input fullWidth value={value ?? ''} onChange={event => onChange(event.target.value)} placeholder="Value" />
)