import { action } from 'mobx';
import { userStore } from 'src/stores';

export function tryAction(task, suppress = false) {
  return task.catch(async error => {
    console.error('tryAction', error.message);
    const status = +error.status || 500;

    if (status === 401) { return userStore.clean() }
    if (!suppress) { throw error }
  });
}

export async function runAction(task) {
  try {
    userStore.cleanSnack();
    userStore.startLoading();
    return await tryAction(action(task)());
  }
  catch ({ status, code, message }) {
    userStore.displayErrorSnack(message || code);
  }
  finally {
    userStore.stopLoading();
  }
}

export async function runActivity(task) {
  try {
    userStore.cleanSnack();
    userStore.startLoading();
    await tryAction(action(task)());
  }
  finally {
    userStore.stopLoading();
  }
}

export async function sleep(ms) {
  return new Promise(resolve => {
    setTimeout(function () { resolve() }, ms);
  })
}