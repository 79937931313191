import { useState } from 'react';
import { Snackbar, Alert } from '@mui/material';

export const MessageSnack = ({ open, onClose, snack }) => {
  const [value, setValue] = useState(snack);
  if (open && value !== snack) {
    setValue(snack);
  }
  return (
    <Snackbar open={open} onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert severity={value.variant} variant="filled" onClose={onClose}>{value.message}</Alert>
    </Snackbar>
  )
}