import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Unstable_Grid2 as Grid, CircularProgress } from '@mui/material';

import { runAction } from 'src/services/utils';
import { useStore } from 'src/stores';
import { CODE_ACTIVATION_TOKEN_EXPIRED } from 'shared/status-error/codes';

export const Activate = observer(() => {
  const { userStore } = useStore();

  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    runAction(async () => {
      try {
        await userStore.activate({ token });
        userStore.displaySuccessSnack(`The account was successfully activated`);
      }
      catch (error) {
        if (error.code !== CODE_ACTIVATION_TOKEN_EXPIRED) { throw error }
        userStore.displayErrorSnack(`Your activation link has expired. Please click on the ‘Forgot Password?’ link to send you a new one.`);
      }
      finally {
        navigate('/', { replace: true });
      }
    })
  }, [token]);

  return (
    <Grid container xs={11} md={6} alignContent="center" justifyContent="center" direction="column">
      <CircularProgress sx={{ color: 'secondary.main' }} size={48} />
    </Grid>
  )
})