import superagent from 'superagent';

async function request(method, uri, data, token) {
  try {
    const task = superagent(method, `${ENV.PORTAL_ENDPOINT}${uri}`)
      .set('Content-Type', 'application/json')
      .set('Cache-Control', 'no-cache,no-store,must-revalidate,max-age=-1,private')
      .set('Pragma', 'no-cache')
      .withCredentials();

    if (token) {
      task.set('Authorization', `Basic ${token}`);
    }

    if (data) {
      method === 'GET' ? task.query(data) : task.send(data);
    }

    const result = await task;
    return result.body || result;
  }
  catch (error) {
    if (error.response?.type === 'application/json') {
      error.code = error.response?.body?.code ?? 'unknown';
      error.message = error.response?.body?.message ?? error.message;
    }
    throw error;
  }
}

// Customer

export function login(data) {
  return request('POST', '/login', data);
}

export function logout(token) {
  return request('POST', '/logout', {}, token);
}

export function getEmailBySubscription(data) {
  return request('GET', '/subscription-email', data);
}

export function registerCustomer(data) {
  return request('POST', '/customer', data);
}

export function activate(data) {
  return request('POST', '/activate', data);
}

export function resetPassword(data) {
  return request('POST', '/reset-password', data);
}

export function changePassword(data) {
  return request('POST', '/change-password', data);
}

export function getCustomer(token) {
  return request('GET', '/customer', {}, token);
}

export function generatePortalSession(token) {
  return request('POST', '/generate_portal_session', {}, token);
}

export function getSupportUrl(token) {
  return `${ENV.PORTAL_ENDPOINT}/support`;
}

// Subscriptions

export function fetchSubscriptions(token) {
  return request('GET', '/subscriptions', {}, token);
}

export function updateSubscriptions(data, token) {
  return request('PUT', '/subscription', data, token);
}

export function getExportSubscriptionUrl() {
  return `${ENV.PORTAL_ENDPOINT}/subscription-export`;
}

export function cancelSubscription(data, token) {
  return request('POST', '/subscription-cancel', data, token);
}

export function removeScheduledCancellation(data, token) {
  return request('POST', '/subscription-remove-cancellation', data, token);
}

export function estimateSubscription(data, token) {
  return request('POST', '/subscription-estimate', data, token);
}

export function proceedSubscriptions(data, token) {
  return request('POST', '/proceed-subscriptions', data, token);
}

export function shareSubscription(data, token) {
  return request('POST', '/subscription-share', data, token);
}

export function fetchSharedSubscription(data) {
  return request('GET', '/shared-subscription', data);
}

export function updateSharedSubscription(data) {
  return request('PUT', '/shared-subscription', data);
}

// Plans

export function fetchPlans(token) {
  return request('GET', '/plans', {}, token);
}

// Checkout

export function getCheckout(data) {
  return request('GET', '/checkout', data);
}

export function checkout(data) {
  return request('POST', '/checkout', data);
}

export function estimateCheckout(data) {
  return request('POST', '/checkout-estimate', data);
}