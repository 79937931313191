import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { InputBase, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Search as SearchIcon } from '@mui/icons-material';

const ResizebleSearchBox = styled(InputBase)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
  
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
  '& .MuiInputBase-input': {
    transition: theme.transitions.create('width'),
    width: '12ch',

    '&:focus': {
      width: '20ch',
    },
  }
}))

export const SearchField = ({ placeholder = 'Search…', value = '', onChange }) => {
  const [query, setQuery] = useState(value);
  const debouncedSearch = useDebouncedCallback(value => onChange && onChange(value), 100);

  function handleChange(event) {
    debouncedSearch(event.target.value);
    setQuery(event.target.value);
  }

  return (
    <ResizebleSearchBox
      type="search"
      placeholder={placeholder}
      value={query} onChange={handleChange}
      startAdornment={<InputAdornment position="start" sx={{ pl: 1 }}><SearchIcon /></InputAdornment>}
    />
  )
}