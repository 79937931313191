import LIVR from 'livr';

const messages = {
  TOO_SHORT: 'Your password must be at least 8 characters long and contain at least one digit and one special symbol.',
  FIELDS_NOT_EQUAL: 'Your password confirmation does not match your password.',
  DUPLICATED_EMAIL: 'Duplicated email.',
  REQUIRED: 'Please fill in this field.',
}

function translateCode(code) {
  return messages[code] || code || '';
}

LIVR.Validator.registerDefaultRules({
  predicate: function (arg1) {
    return function (value, allValues) {
      return arg1(value, allValues);
    }
  }
});

export function validatorResolver(schema) {
  const validator = new LIVR.Validator(schema);

  return (data) => {
    const values = validator.validate(data);
    const errors = !values ? validator.getErrors() : {};

    return { values, errors };
  }
}

export { LIVR, translateCode };
