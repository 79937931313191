const PASSWORD_MIN = 8;
const PASSWORD_MAX = 128;

/**
 * Validate passwords.
 * @param {!string} password - The password value.
 * @return {number} Score: 0 - Invalid, 1 - weak, 2 - avarage, 3 - good, 4 - excellent.
 */
export function scorePassword(password = '') {
  const normilizedPassword = password.replace(/\s+/g, ' ');
  const length = normilizedPassword.length;

  const unique = new Set(normilizedPassword);
  if (length < PASSWORD_MIN || password.length > PASSWORD_MAX || unique.size < 2) { return 0 }
  if (!normilizedPassword.match(/[a-z]+/)) { return 1 }

  // Allow less then 12 symbols password with digits and non-letters symbols
  if (length < 12) {
    return (!!normilizedPassword.match(/[\w]+/) && !!normilizedPassword.match(/[\d]+/) && !!normilizedPassword.match(/[^\d\w]+/)) ? 1 : 0;
  }

  let score = unique.size / length > 0.5 ? 6 : 0;

  const count = (len, x0, x1) => Math.max(0, Math.min(len - x0, x1));
  score += count(length, 0, 1) * 4 + count(length, 1, 7) * 2 + count(length, 8, 20) * 1.5 + count(length, 21, Number.MAX_VALUE) * 1;

  if (!!normilizedPassword.match(/[^a-z\s]+/)) { score += 6 }

  if (score < 20) { return 1 }
  if (score < 30) { return 2 }
  if (score < 40) { return 3 }

  return 4;
}