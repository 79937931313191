import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Unstable_Grid2 as Grid, Button, Alert, AlertTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Card, CardHeader, CardActions } from '@mui/material';

import { LicenseCard } from './LicenseCard';
import { SubscriptionTitle, SubscriptionSubheader } from './SubscriptionHeaders';
import { runAction, runActivity } from 'src/services/utils';
import { useStore } from 'src/stores';

export const SharedSubscription = observer(() => {
  const { userStore } = useStore();

  const { token } = useParams();
  const [subscription, setSubscription] = useState();

  useEffect(() => {
    runAction(async () => {
      setSubscription(normilizeSubscription(await userStore.fetchSharedSubscription(token)));
    });
  }, [token]);

  function handleChangeName(name) {
    const modified = name !== (subscription.origin.name || '');
    setSubscription({ ...subscription, data: { ...subscription.data, name }, modified });
  }

  function handleUpdateLicenses(licenses) {
    setSubscription({ ...subscription, data: { ...subscription.data, licenses }, modified: true });
  }

  function handleResetClick() {
    setSubscription(normilizeSubscription({ ...subscription, data: subscription.origin, modified: false }));
  }

  async function handleSaveSubscription() {
    await runActivity(async () => {
      setSubscription(normilizeSubscription(await userStore.updateSharedSubscription(token, subscription)));
    })
  }

  if (!subscription) { return null }

  return (
    <Grid container xs={11} md={7} direction="column" wrap="nowrap" sx={{ height: 1 }} >

      {subscription.modified &&
        <Grid xs="auto" sx={{ mb: 1 }} >
          <Alert severity="warning">
            <AlertTitle>Some changes have been made to your subscription(s). You will need to click on Proceed button at the bottom of the screen for the changes to take effect</AlertTitle>
          </Alert>
        </Grid >
      }

      <Grid xs container direction="column" rowSpacing={1}>
        <Card>
          <CardHeader
            title={<SubscriptionTitle title={subscription.data?.name} defaultTitle={'Enter subscription name'} onChange={handleChangeName} />}
            subheader={<SubscriptionSubheader subscription={subscription} />}
          />
        </Card>

        <Grid xs>
          <LicenseCard subscription={subscription.origin?.id} licenses={subscription.data.licenses} manageOnly disabled={userStore.isLoading}
            onUpdate={handleUpdateLicenses} updated_at={subscription.data.updated_at} token={userStore.token}
          />
        </Grid>

        <Card>
          <CardActions>
            <Button onClick={handleResetClick} disabled={!subscription.modified || userStore.isLoading}>Reset</Button>
            <LoadingButton variant="contained" color="secondary"
              onClick={handleSaveSubscription} loading={userStore.isLoading} disabled={!subscription.modified}>Proceed</LoadingButton>
          </CardActions>
        </Card>
      </Grid >

    </Grid >
  )
})

function normilizeSubscription(subscription) {
  const { licenses = [], plan_quantity } = subscription.data;
  subscription.data.licenses = [...licenses, ...(new Array(Math.max(0, plan_quantity - licenses.length)).fill({ name: '' }))];
  return subscription;
}