import shortid from 'shortid';
import { makeAutoObservable, runInAction } from 'mobx';
import { registerCustomer, login, logout, activate, resetPassword, changePassword, getCustomer, getEmailBySubscription } from 'src/api';
import { fetchSubscriptions, updateSubscriptions, estimateSubscription } from 'src/api';
import { cancelSubscription, removeScheduledCancellation } from 'src/api';
import { proceedSubscriptions, generatePortalSession } from 'src/api';
import { shareSubscription, fetchSharedSubscription, updateSharedSubscription } from 'src/api';
import { checkout, estimateCheckout, getCheckout, fetchPlans } from 'src/api';
import { reflect } from 'shared/utils/utils';

export default class UserStore {
  token;
  customer = {};
  card = {};

  plans = [];
  subscriptions = [];

  _loading = 0;
  get isLoading() {
    return this._loading > 0;
  }

  snack = {};

  get isAuthorized() {
    return !!this.token;
  }

  constructor() {
    makeAutoObservable(this);
  }

  async register(data) {
    await registerCustomer(data);
  }

  async resetPassword(data) {
    await resetPassword(data);
  }

  async changePassword(data) {
    const { token, customer, card } = await changePassword(data);

    runInAction(() => {
      this.customer = customer;
      this.card = card;
      this.token = token;
    });

    this.plans = await fetchPlans(token);
  }

  async login(data) {
    const { token, customer, card } = await login(data);

    runInAction(() => {
      this.customer = customer;
      this.card = card;
      this.token = token;
    });

    this.plans = await fetchPlans(token);
  }

  async activate(data) {
    const { token, customer, card } = await activate(data);

    runInAction(() => {
      this.customer = customer;
      this.card = card;
      this.token = token;
    });

    this.plans = await fetchPlans(token);
  }

  async logout() {
    if (this.token) {
      const token = this.token;
      this.clean();
      await reflect(logout(token));
    }
  }

  async getEmailBySubscription(data) {
    const { email } = await getEmailBySubscription(data);
    return email;
  }

  async getCustomer() {
    const [{ token, customer, card }, plans] = await Promise.all([getCustomer(this.token), fetchPlans(this.token)]);

    runInAction(async () => {
      this.token = token;
      this.customer = customer;
      this.plans = plans;
      this.card = card;
    })
  }

  clean() {
    window.charge && window.charge.logout()

    runInAction(() => {
      this.token = null;
      this.customer = null;
      this.card = null;
      this.subscriptions = [];
      this.plans = [];
    })
  }

  createSubscription(data) {
    return {
      key: shortid.generate(),
      data: { ...data, plan_quantity: data.licenses?.length ?? 1 },
      origin: { ...data, plan_quantity: data.licenses?.length ?? 1 },
    };
  }

  modifySubscription(subscription) {
    if (this.subscriptions.find(item => item.key === subscription.key)) {
      this.subscriptions = this.subscriptions.map(item => (item.key === subscription.key ? subscription : item));
    }
    else {
      this.subscriptions = [subscription, ...this.subscriptions];
    }
  }

  async fetchSubscriptions() {
    const result = await fetchSubscriptions(this.token);
    this.subscriptions = result.map(item => ({ key: item.id, origin: item, data: item }));
  }

  async updateSubscriptions({ key, data }) {
    const result = await updateSubscriptions({ id: key, name: data.name, licenses: data.licenses, products: data.products }, this.token);

    const subscription = { key: result.id, origin: result, data: result };
    this.subscriptions = this.subscriptions.map((item) => item.key === subscription.key ? subscription : item);
  }

  async cancelSubscription(id) {
    const result = await cancelSubscription({ id }, this.token);

    const subscription = { key: result.id, origin: result, data: result };
    this.subscriptions = this.subscriptions.map((item) => item.key === subscription.key ? subscription : item);

    return subscription;
  }

  async removeScheduledCancellation(id) {
    const result = await removeScheduledCancellation({ id }, this.token);

    const subscription = { key: result.id, origin: result, data: result };
    this.subscriptions = this.subscriptions.map((item) => item.key === subscription.key ? subscription : item);

    return subscription;
  }

  async estimateSubscription(subscription, data) {
    const payload = { plan_id: subscription.data?.plan_id, licenses: subscription.data?.licenses };
    Object.assign(payload, !!data.plan_id && { plan_id: data.plan_id }, !!data.licenses && { licenses: data.licenses });

    const estimates = await estimateSubscription({ id: subscription.origin?.id, ...payload }, this.token);
    return { key: subscription.key, origin: subscription.origin, data: { ...subscription.data, ...estimates.subscription_estimate }, estimates };
  }

  async shareSubscription(id, share) {
    const { shared_token } = await shareSubscription({ id, share }, this.token);
    this.subscriptions = this.subscriptions.map(item => item.key === id ? { ...item, origin: { ...item.origin, shared_token } } : item);
    return shared_token;
  }

  async fetchSharedSubscription(key) {
    const subscription = await fetchSharedSubscription({ key });
    return { key: subscription.id, origin: subscription, data: subscription };
  }

  async updateSharedSubscription(key, subscription) {
    const result = await updateSharedSubscription({ key, name: subscription.data.name, licenses: subscription.data.licenses });
    return { key: result.id, origin: result, data: result };
  }

  async fetchPlans() {
    this.plans = await fetchPlans(this.token);
  }

  resetSubscriptionChanges() {
    this.subscriptions = this.subscriptions.reduce((acc, item) => {
      if (item.origin?.id) { acc.push({ key: item.key, origin: item.origin, data: item.origin }) }
      return acc;
    }, []);
  }

  async proceedPayment() {
    const subscriptions = this.subscriptions
      .reduce((acc, { data, estimates }) => (estimates && acc.push(data), acc), []);

    const result = await proceedSubscriptions(subscriptions, this.token);
    this.subscriptions = result.map(item => ({ key: item.id, origin: item, data: item }));

    await this.getCustomer();
  }

  async generatePortalSession() {
    return await generatePortalSession(this.token);
  }

  async proceedCheckout(data) {
    return await checkout(data);
  }

  async getCheckout(data) {
    return await getCheckout(data);
  }

  async estimateCheckout(data) {
    return await estimateCheckout(data);
  }

  displaySuccessSnack(message) {
    this.snack = { variant: 'success', message };
  }

  displayErrorSnack(message) {
    this.snack = { variant: 'error', message };
  }

  cleanSnack() {
    this.snack = {};
  }

  startLoading() {
    this._loading = this._loading + 1;
  }

  stopLoading() {
    this._loading > 0 && this._loading--;
  }
}