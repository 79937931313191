import { useMemo } from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

const levels = {
  0: { color: '#F04800', title: 'keep typing' },
  1: { color: '#F04800', title: 'weak' },
  2: { color: '#EE8002', title: 'average' },
  3: { color: '#6FAC2A', title: 'good' },
  4: { color: '#1AA043', title: 'excellent' },
}

export const PasswordMetter = ({ score, empty }) => {
  const level = useMemo(() => levels[score], [score]);

  return (
    <Box display='flex' alignItems="center">
      <Box mr={1} flexGrow={1}>
        <LinearProgress sx={{ height: 14, '& .MuiLinearProgress-barColorPrimary': { backgroundColor: level.color } }} variant="determinate" value={score * 25} />
      </Box>
      <Box minWidth={70}>
        <Typography sx={{ float: 'right', color: level.color }} variant="caption" component="span">{empty ? '…' : level.title}</Typography>
    </Box>
    </Box >
  )
}